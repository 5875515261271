import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';
import EmailForm from './eligibility/email';
import BottomCircularRect from '../assets/svgs/bottomCircularRect.svg';

import ReviewerOne from '../assets/images/ReviewerOne.png';
import Stars5_5 from '../assets/svgs/Stars5_5.svg';
import {
  AndroidView,
  BrowserView,
  IOSView,
  MobileView,
} from 'react-device-detect';
import {
  AppleAppStoreIconRoundButton,
  GooglePlayIconRoundButton,
} from './appStoreButtons';

export function ReviewBubble(props) {
  return (
    <>
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: colors.white,
            height: 120,
            borderRadius: 60,
            maxWidth: 400,
            paddingRight: 20,
          }}
        >
          <Box
            style={{
              minWidth: 70,
              minHeight: 70,
              maxWidth: 70,
              maxHeight: 70,
              borderRadius: 35,
              overflow: 'hidden',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <Image
              src={props.image}
              style={{
                objectFit: 'cover',
                minWidth: 70,
                minHeight: 70,
                maxWidth: 70,
                maxHeight: 70,
              }}
            />
          </Box>
          <Flex flexGrow="1">
            <Text variant={'h600Bold'}>
              &lsquo;&lsquo;{props.review}&rsquo;&rsquo;
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Box
            style={{
              borderStyle: 'solid',
              borderBottom: '30px solid transparent',
              borderLeft: '30px solid transparent',
              borderRight: '30px solid transparent',
              borderTop: '30px solid #FFFFFF',
              height: 0,
              width: 0,
            }}
          />
        </Flex>
        <Text
          variant="h400Regular"
          style={{ color: colors.white, opacity: 0.4, textAlign: 'center' }}
        >
          App Store review by {props.reviewer}
        </Text>
        <Flex alignItems="center" justifyContent="center">
          <Stars5_5 />
        </Flex>
      </Box>
    </>
  );
}

export function ReviewClincher(props) {
  return (
    <>
      <Box backgroundColor={colors.black}>
        <BottomCircularRect fill={props.topColor} />
      </Box>
      <Wrapper bg={colors.black} pt={150} pb={150}>
        <Flex
          flexDirection={['column-reverse', 'column-reverse', 'row']}
          alignItems="center"
          justifyContent="center"
        >
          <Flex flexGrow="1">
            <BrowserView>
              <EmailForm />
            </BrowserView>
            <MobileView>
              <Box pt={50}>
                <Heading
                  sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'bold',
                    fontWeight: 700,
                    fontSize: ['30px', '30px', '30px'],
                    lineHeight: ['40px', '40px', '44px'],
                  }}
                  color={colors.white}
                  textAlign="center"
                  mb={30}
                >
                  Get started for free.
                </Heading>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IOSView>
                    <AppleAppStoreIconRoundButton />
                  </IOSView>
                  <AndroidView>
                    <GooglePlayIconRoundButton />
                  </AndroidView>
                </Flex>
                <Text
                  textAlign="center"
                  variant={'h300Medium'}
                  color={'#FFFFFF'}
                  opacity="70%"
                  mt={20}
                >
                  DOWNLOAD THE APP
                </Text>
              </Box>
            </MobileView>
          </Flex>
          <Flex flexGrow="1">
            <ReviewBubble
              image={ReviewerOne}
              review="I already got my advance! Fast and easy."
              reviewer="Erika"
            />
          </Flex>
        </Flex>
      </Wrapper>
    </>
  );
}
