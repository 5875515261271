import React from 'react';
import theme from '../../components/theme';
import { ThemeProvider } from 'emotion-theming';
import Wrapper from '../../components/wrapper';
import EmailForm from '../../components/eligibility/email';
import { DocumentHeader } from '../../components/layout';
import { SimpleNavbar } from '../../components/overlay';
import { Box, Flex } from 'rebass';
import { ReviewBubble } from '../../components/reviewClincher';
// @ts-ignore
import ReviewerOne from '../../assets/images/ReviewerOne.png';
import colors from '../../components/themes/colors';

function EligibilityFormPage({ location }) {
  return (
    <Flex bg={colors.footerBlack} height="100vh">
      <ThemeProvider theme={theme}>
        <DocumentHeader title={'Email'} />
        <Wrapper>
          <SimpleNavbar location={location} />
          <Flex
            flexDirection={['column', 'row', 'row']}
            justifyContent="space-between"
            alignItems="center"
            pt={150}
          >
            <EmailForm />
            <Box mt={[100, 0, 0]}>
              <ReviewBubble
                image={ReviewerOne}
                review="I already got my advance! Fast and easy."
                reviewer="Erika"
              />
            </Box>
          </Flex>
        </Wrapper>
      </ThemeProvider>
    </Flex>
  );
}

export default EligibilityFormPage;
